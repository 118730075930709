@import '../Background/color.scss';

.ghost {
  animation: float 3s ease-out infinite;
}

@keyframes float {
  50% {
    transform: translate(0, 20px);
  }
}

.shadow {
  animation: shrink 3s ease-out infinite;
  transform-origin: center center;
  ellipse {
    transform-origin: center center;
  }
}

@keyframes shrink {
  0% {
    width: 90%;
    margin: 0 5%;
  }
  50% {
    width: 60%;
    margin: 0 18%;
  }
  100% {
    width: 90%;
    margin: 0 5%;
  }
}

.clsOne {
  fill: #c5e9fb;
}
.clsTwo,
.clsFour {
  fill: #3b3e40;
}
.clsThree {
  fill: #b2e5fa;
  stroke: #b2e5fa;
}
.clsThree,
.clsFour {
  stroke-miterlimit: 10;
}
.clsFour {
  stroke: #3b3e40;
  stroke-width: 8px;
}
