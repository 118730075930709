@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;600&display=swap');

body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Recursive', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eacfbf;
  color: #3b3e40;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
