@import '../Background/color.scss';
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

@mixin circle($size, $color) {
  position: absolute;
  width: $size;
  height: $size;
  background-color: $color;
  border-radius: 50%;
}

.container {
  width: 400px;
  height: 400px;
  position: relative;
}

.bookShelf {
  position: absolute;
  top: 60%;
  left: 20%;
  width: 60%;
  height: 1.5%;
  border: 3px solid #3b3e40;
  border-radius: 4px;
  background-color: #e0b289;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 90%;
    width: 3%;
    height: 118%;
    border-radius: 50%;
    background-color: #6b6c6c;
    border: 3px solid #3b3e40;
    z-index: -1;
  }

  &:before {
    left: 4%;
  }

  &:after {
    left: 90%;
  }
}

.book {
  position: absolute;
  width: 2%;
  height: 9%;
  border: 3px solid #3b3e40;
  top: 50%;
  left: 31%;
  border-radius: 6%;
  background-color: #ff6f7a;
}

.book:before {
  content: '';
  position: absolute;
  left: -3px;
  right: -3px;
  height: 10%;
  border: 3px solid #3b3e40;
  top: 7%;
  height: 2px;
  background-color: rgb(255, 255, 255);
}

.book:after {
  content: '';
  background-color: #3b3e40;
  position: absolute;
  left: -3px;
  right: -3px;
  top: 80%;
  height: 3px;
}

.book:nth-of-type(2) {
  background-color: #b2e5fa;
  left: 34%;
}

.book:nth-of-type(3) {
  background-color: #e2ec83;
  transform: rotate(-30deg);
  left: 39%;
  height: 8%;
  top: 51%;
}

.book:nth-of-type(4) {
  background-color: #bde585;
  transform: rotate(90deg);
  height: 8%;
  top: 54%;
  left: 25%;
}

.speechBubble {
  width: 8%;
  height: 8%;
  border: 3px solid #3b3e40;
  background-color: rgb(255, 255, 255);
  top: 30%;
  left: 40%;
  position: absolute;
  border-radius: 40%;
  border-bottom-right-radius: 10%;
}

.heart {
  background-color: #f05354;
  position: absolute;
  left: 43%;
  top: 24%;
  height: 51%;
  width: 31%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  transform: rotate(45deg);
}

.heart:after {
  content: '';
  position: absolute;
  transform: rotate(-90deg);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-color: #f05354;
  left: -43%;
  top: 19%;
  width: 100%;
  height: 100%;
}

.elephant {
  position: absolute;
  width: 20%;
  height: 20%;
  top: 41%;
  left: 45%;
}

.body {
  position: absolute;
  width: 66%;
  height: 59%;
  background-color: #c5e9fb;
  border: 3px solid #3b3e40;
  border-top-left-radius: 42%;
  top: 20%;
  left: 9%;
}

.leg {
  position: absolute;
  width: 25%;
  height: 21%;
  background-color: #c5e9fb;
  border: 3px solid #3b3e40;
  border-top: none;
  top: 99%;
  border-bottom-left-radius: 40%;
  border-bottom-right-radius: 40%;
}

.legLeft {
  left: -3px;
}

.legRight {
  left: 69%;
}

.legLeftBehind {
  z-index: -1;
  left: 4px;
  height: 14%;
  top: 105%;
}

.legRightBehind {
  z-index: -1;
  transform: rotate(-50deg);
  left: 100%;
  top: 80%;
  width: 20%;
}

.head {
  position: absolute;
  left: 42%;
  height: 54%;
  width: 59%;
  background-color: #c5e9fb;
  border: 3px solid #3b3e40;
  border-radius: 27%;
  border-bottom-right-radius: 0;
  z-index: 2;
}

.trunk {
  position: absolute;
  width: 46%;
  height: 36%;
  border: 3px solid #3b3e40;
  border-top: none;
  border-bottom-right-radius: 45%;
  border-bottom-left-radius: 30%;
  top: 86%;
  left: 48%;
  background-color: #c5e9fb;

  &:after {
    content: '';
    position: absolute;
    width: 32%;
    height: 50%;
    background-color: #c5e9fb;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 34%;
    border: 3px solid #3b3e40;
    border-bottom: none;
    top: -33%;
    left: -13%;
  }
}

.trunkLine {
  position: absolute;
  top: -62%;
  left: 85%;
  width: 20%;
  height: 20%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  background-color: #3b3e40;
}

.trunkLine:nth-child(2) {
  top: -23%;
  width: 36%;
  left: 77%;
}

.trunkLine:nth-child(3) {
  top: 21%;
}

.eye {
  position: absolute;
  width: 12%;
  height: 13%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-color: rgb(255, 255, 255);
  top: 32%;
  left: 45%;
  border: 3px solid #3b3e40;
}

.eye:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 105%;
  background-color: #3b3e40;
  border-radius: 50%;
  top: 40%;
  left: 40%;
}

.plant {
  width: 8%;
  height: 20%;
  position: absolute;
  top: 41%;
  left: 69%;
  z-index: 1;
}

.pot {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  clip-path: polygon(0% 0%, 10% 100%, 90% 100%, 100% 0%);
  background-color: rgb(255, 148, 107);
}

.potContainer {
  position: absolute;
  top: 62%;
  left: 7%;
  height: 34%;
  width: 100%;
  background-color: #3b3e40;
  clip-path: polygon(0% 0%, 10% 100%, 90% 100%, 100% 0%);
  content: '';
  z-index: -1;
}

.potTop {
  position: absolute;
  top: 54%;
  width: 98%;
  border-radius: 30%;
  border: 3px solid #3b3e40;
  background-color: #ff946b;
  height: 6%;
}

.stem {
  background-color: #3b3e40;
  height: 48%;
  width: 3px;
  position: absolute;
  left: 52%;
  top: 8%;
}

.leaf {
  background-color: #bde585;
  width: 46%;
  height: 11%;
  border: 3px solid #3b3e40;
  border-radius: 50%;
  transform: rotate(15deg);
  position: absolute;
  top: -6%;
  border-top-right-radius: 100%;
  border-bottom-left-radius: 100%;
  border-top-left-radius: 20%;
  border-bottom-right-radius: 20%;
}

.leaf:nth-child(2) {
  top: 20%;
}

.leaf:nth-child(3) {
  top: 10%;
  left: 54%;
  transform: rotate(290deg);
}
.greeting {
  font-weight: 300;
  color: #3b3e40;
  font-family: 'Gochi Hand', cursive;
}
@media (max-width: 850px) {
  .greeting {
    margin-top: -100px;
  }
}
